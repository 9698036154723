module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  whiteListMintCount: 1,
  totalGas: 300000,
  MaxMintCounts: 1000,
  MINTCONTRACT_ADDRESS: '0x4c2596B105361E2547F38B0c2cf13d1F06FCF3D2',
  POODLETOKEN_ADDRESS: '0xC18f99CE6DD6278BE2D3f1e738Ed11623444aE33',
  BurnPoodle: 1000000000,
  FREE_MINT_LIMIT: 200,
}
